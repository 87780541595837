/* -------------------------------------------------------------------------

	PUNITED.top

	トップページのスクリプトを定義
	※jQuery必須
	Potential United inc.

------------------------------------------------------------------------- */

// グローバル名前空間定義
var PUNITED;
if (!PUNITED) PUNITED = {};
if (!PUNITED.top) PUNITED.top = {};

(function (){
	// グローバル名前空間オブジェクトをローカルオブジェクトに置き換え
	var ns = PUNITED.top;

	/* -----------------------------------------------------

	----------------------------------------------------- */
	ns.mv = function() {

		var $mvTitle = $('.mv__title');
		var $mvScroll = $('.mv__scroll');


		setTimeout(function() {
			$mvTitle.addClass('mv__title--show');
		}, 1000);
		setTimeout(function() {
			$mvScroll.addClass('mv__scroll--show');
		}, 1750);

}
ns.smoothBg = function() {

	//変数設定
	var elm = $(".product, .wrapper__section"); //背景を動かす要素
	var sp = 3; //数値が小さいほど視差が大きくなる
	var win = $(window);

	//配列作成
	pos = [];
	os = [];
	osNextCheck = [];
	osNext = [];
	h = [];
	posArray = [];
	posY = [];

	//配列設定
	elm.each(function(i){
		var self = $(this);
		pos[i] = self.css("background-position");
		os[i] = self.offset().top;
		osNextCheck[i] = self.next().size();
		if(osNextCheck[i] != 0){
			osNext[i] = self.next().offset().top;
		}
		else{
			h[i] = self.height();
			osNext[i] = os[i] + h[i];
		}
		if(pos[i]){
			var posArraySet = pos[i].split(" ");
			posArray[i] = new Array(posArraySet[0], posArraySet[1]);
			posY[i] = posArray[i][1].replace("px", "");
		}
		else{
			posY[i] = elm.css("background-position-y").replace("px", "");
		}
	});

			//スクロールイベント
	win.scroll(function(){
		var y = $(this).scrollTop();
		var winH = win.height();
		elm.each(function(i){
			var self = $(this);
			if(pos[i]){
				if(y > os[i] - winH && y < osNext[i]){
					self.css("background-position", posArray[i][0] + parseInt(-y / sp + os[i] / sp) + "px");
				}
			}
			else{
				self.css("background-position-y", parseInt(-y / sp + os[i] / sp) + "px");
			}
		});
	});


}
ns.slider = function() {
	var $slider = $('.mv');
	var $sliderItems = $('.mv__item');
	var _delay = 4000;
	var _index = 0;

	$sliderItems.eq(_index).addClass('mv__item--current');

	setInterval(function() {
		_index += 1;
		if (_index >= $sliderItems.length) {
			_index = 0;
		}
		$sliderItems
			.removeClass('mv__item--current')
			.eq(_index)
			.addClass('mv__item--current');

	}, _delay);
}

})();

$(function(){
	$(window).on('load', function() {
		PUNITED.top.mv();
	});


	$('.mv__scroll').on('click', function() {
		var _targetPos = $(window).height() - $('.site_header').height();
		$('body, html').animate({
			scrollTop: _targetPos + 'px'
		}, 'normal');
	});

	var $obj = PUNITED.useful.checkBreakPont([679]);
	$obj.on("onPointChanged onPointInit", function(event, point){
		switch( point ){
			case 1:
				PUNITED.top.slider();
			break;
			default:
				PUNITED.top.smoothBg();
			break;
		}
	});

	$(".news:not(:has(ul > li))").hide();
});
